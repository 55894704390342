.DecoratedText {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    bottom: -4px;
    height: 2px;
    width: 0;
    left: 0;
    transition-property: width;
    transition-duration: 0.3s;
    /* orange-1 */
    background-color: #007681;
  }

  &.isSelected::after {
    width: 50%;
  }
}

.ListItem {
  &:hover {
    .DecoratedText:after {
      width: 100%;
    }
  }
}
