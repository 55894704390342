.ListItem {
  &.isSelected {
    .isSelectedDecorator {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -4px;
        height: 2px;
        width: 35%;
        left: 0;
        /* orange-1 */
        background-color: #ff8a00;
      }
    }
  }
}

.ListItem:last-child {
  box-shadow: none;
}
.ListItemContent {
  position: relative;
}
