@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    background-color: rgb(248 250 252);
    font-family: 'Lato', 'sans-serif';
    box-sizing: border-box;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    overflow: hidden;
  }
}

.ReactCollapse--collapse {
  width: 100%;
  transition: height 500ms;
}

@layer components {
  .bg-stripes {
    background: repeating-linear-gradient(
      45deg,
      var(--color-green-1) 0px,
      var(--color-green-1) 20px,
      transparent 20px,
      transparent 40px
    );
  }

  .payed {
    border: solid 5px rgb(102 162 102 / 50%) !important;
    border-radius: 10px;
  }
  .awaiting-picking {
    border: solid 5px rgb(253 224 71 / 50%) !important;
    border-radius: 10px;
  }

  .bg-awaiting-picking {
    background-color: rgb(253 224 71 / 50%);
  }
  .bg-picked {
    background-color: rgb(34 106 183 / 50%);
  }
  .picked {
    border: solid 5px rgb(34 106 183 / 50%) !important;
    border-radius: 10px;
  }
  .refunded {
    border: solid 5px rgb(253 71 71 / 50%) !important;
    border-radius: 10px;
  }
}
